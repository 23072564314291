<!--
 * @Author: your name
 * @Date: 2020-03-21 15:47:02
 * @LastEditTime: 2022-11-03 14:45:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\components\header\navBar.vue
-->
<template>
    <div class="navBar">
        <button
            class="navLeft iconfont iconjiantouzuo"
            v-if="navBtnState"
            @click="navLeftMove"
        ></button>
        <ul class="navContent">
            <template v-for="(item, index) in routes">
                <li
                    v-if="item.meta.title"
                    :key="`navBar_${index}`"
                    :class="{'active': currentRouteCode == item.code}"
                    @click="gainSideBarRoutes(item)"
                >
                    <i class="iconfont" :class="item.css"></i>
                    {{ item.meta.title }}
                </li>
            </template>
        </ul>
        <button
            class="navRight iconfont iconjiantouyou"
            v-if="navBtnState"
            @click="navRightMove"
        ></button>
    </div>
</template>

<script>
export default {
    props: {
        routes: {
            type: [Array, String],
        },
    },
    data() {
        return {
            currentRouteCode: '',
            navLeft: 0,
            navWidth: 0,
            navBoxWidth: 0,
            navBtnState: false,
        };
    },
    watch: {
        routes: {
            immediate: true,
            handler: function () {
                this.$nextTick(function () {
                    const _that = this;
                    setTimeout(function () {
                        _that.navWidth = _that.$('.navBar').width();
                        _that.navBoxWidth = _that.$('.navContent').width();
                        if (_that.navBoxWidth > _that.navWidth) {
                            _that.navBtnState = true;
                            _that.navLeft = -(_that.navBoxWidth - _that.navWidth + 50);
                            _that.$forceUpdate();
                        }
                    }, 200);
                });
            },
        },
        $route: {
            handler(to) {
                const pata = to.path.split('/');
                this.currentRouteCode = pata[1];
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        gainSideBarRoutes(data) {
            this.isChildren(data);
            this.$emit('obtainSideBar', data);
        },
        isChildren(data) {
            if (data.children) {
                if (data.children[0].children) {
                    this.isChildren(data.children[0]);
                } else {
                    this.$router.push({ path: data.children[0].path });
                    this.$emit('addNavTabs', data.children[0]);
                }
            }
        },
        navLeftMove() {
            this.navWidth = this.$('.navContent').css('left', 0);
        },
        navRightMove() {
            this.navWidth = this.$('.navContent').css(
                {
                    left: this.navLeft + 'px',
                });
        },
    },
    mounted() {},
};
</script>

<style lang="stylus" scoped>
.navBar
    height 0.4rem
    background rgba(38, 46, 62, 0.2)
    overflow hidden
    position relative
    button
        width 0.3rem
        height 100%
        border none
        font-size 0.16rem
        background #022782
        color #fff
        position absolute
        cursor pointer
        top 0
        z-index 2
        border-bottom 0.04rem solid #022782
        &.navLeft
            left 0
        &.navRight
            right 0
    .navContent
        transition 0.3s
        padding 0 0.23rem
        display flex
        white-space: nowrap;
        position absolute
        left 0
        top 0
        li
            font-size 0.18rem
            line-height 0.4rem
            height 0.4rem
            color #fff
            display inline-block
            text-align center
            text-decoration initial
            padding 0 0.24rem
            cursor pointer
            &.active, &:hover
                color #fff
                background #006EFF
            i
                font-size 0.2rem
</style>
